import { createRef, default as React, useState, useRef, useEffect, useMemo } from "react"
import { Link } from 'gatsby'
import algoliasearch from "algoliasearch/lite"
import { navigate } from "@reach/router"  
import qs from 'qs'
import { format } from 'date-fns'
import nb from 'date-fns/locale/nb'
import { 
  InstantSearch,
  Hits,
  Pagination,
  Configure
} from "react-instantsearch-dom"
import SEO from '../components/seo'
import SearchBox from "../components/search-box"
import SearchStats from "../components/search-stats"
import SearchRefinementList from "../components/search-refinement-list"
import StateResults from "../components/search-state-results"
import useClickOutside from "../components/use-click-outside"

const Hit = (props) => {
  return (
    <Link to={props.hit.href} className="flex justify-start -ml-4 -mr-4 lg:ml-0 lg:mr-0 p-4 lg:p-6 hover:bg-purple-light no-underline mb-2">
      <figure className="w-24 lg:w-48 flex-shrink-0 block mr-4 lg:mr-8">
        <img src={props.hit.image} alt="" />
      </figure>
      <div>
        <div className="font-semibold text-lg md:text-2xl">
          <span>{props.hit.title}</span>
        </div>
        <div className="text-xs mb-1 md:mb-3">
          <span className="inline-block">{props.hit.type}</span>
          {props.hit.createdAt && props.hit.type == 'Bloggartikkel' && (
            <span className="inline-block"><span className="inline-block mx-1">&bull;</span>Skrevet {format(new Date(props.hit.createdAt), 'Do MMMM YYYY', { locale: nb })}</span>
          )}
        </div>
        
        <div className="hit-description text-sm md:text-base">
          {props.hit.body && props.hit.body.length > 175 ? props.hit.body.substring(0, 175) + '...' : props.hit.body }
        </div>

        {props.hit.categoryTitle && (<span className="mt-2 uppercase tracking-wide label text-red bg-primary-light inline-block rounded-sm py-1 px-2 text-xs">
          {props.hit.categoryTitle}
        </span>)}
      </div>
    </Link>
  );
}

const Search = (props) => {
  const rootRef = createRef()
  const [hasFocus, setFocus] = useState(false)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  useClickOutside(rootRef, () => setFocus(false))

  const location = props.location
  const DEBOUNCE_TIME = 400

  const createURL = state => `/search?${qs.stringify(state)}`;
  const searchStateToUrl = searchState => searchState ? createURL(searchState) : ''
  const urlToSearchState = ({ search }) => qs.parse(search.slice(1))

  const [searchState, setSearchState] = useState(urlToSearchState(location))
  const debouncedSetStateRef = useRef(null);

  const onSearchStateChange = (updatedSearchState) => {
    clearTimeout(debouncedSetStateRef.current)

    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(updatedSearchState))
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState)
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location))
  }, [location]);

  return (
  <div>
    <SEO title='Søk' />
    <div ref={rootRef}>
      <InstantSearch
        searchClient={searchClient}
        indexName="content"
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Configure
          hitsPerPage={20}
        />
        <div className='bg-primary-light header-offset'>
          <div className='text-center md:max-w-lg py-12 mx-auto px-5'>
            <SearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} size="large" />
            <div className="font-semibold text-3xl mt-4">Viser <SearchStats /> for "{searchState.query}"</div>
          </div>
        </div>
        <div className='bg-primary-background-sand'>
          <div className="grid lg:grid-cols-6 gap-4 lg:gap-8 py-6 lg:py-12 px-5 max-w-screen-xl mx-auto">
            <div className="col-span-2">
              <div className="uppercase font-bold text-sm mb-4">Filtrér innholdstype</div>
              <SearchRefinementList attribute="type" />
            </div>
            <div className='col-span-4'>
              <Hits hitComponent={Hit} />
              <StateResults showMore={false} />
              <div className="mt-8 lg:px-6">
                <Pagination />
              </div>
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  </div>
  )
}

export default Search
